var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.questionnaires)?_c('div',{staticClass:"members-profile"},[_c('v-container',{staticClass:"first-part"},[(_vm.member)?_c('Breadcrumbs'):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-flex pb-0 mb-12"},[_c('span',{staticClass:"icomoon icon-patient"}),_c('h1',{staticClass:"d-flex ml-2 page-title"},[_vm._v(" "+_vm._s(_vm.$t("profile"))+" ")])])],1),_c('BasicInfo',{attrs:{"isLifeChamps":_vm.isLifeChamps,"member":_vm.member}})],1),_c('v-container',{class:("medical-info-container " + (_vm.isLifeChamps ? 'mt-8' : 'mt-16')),attrs:{"fluid":""}},[_c('v-container',[_c('v-tabs',{staticClass:"mb-7 custom-tabs",attrs:{"align-with-title":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":_vm.$store.getters['auth/user']
								? _vm.$store.getters['auth/user'].theme_config.primary_color
								: ''}}),_vm._l((_vm.items),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('v-tabs-items',{staticClass:"pl-5",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-container',[(_vm.showEdmontonSymptoms)?_c('v-row',{staticClass:"programs mb-14"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"h2 mb-5 pl-1 title"},[_vm._v("programs")])]),_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('p',[_vm._v("adhera for cancer")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("2")]),_vm._v(" months "),_c('strong',[_vm._v("54")]),_vm._v(" "+_vm._s(_vm.$t("days"))+" ")])])],1),_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('p',[_vm._v("diabetes")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("54")]),_vm._v(" "+_vm._s(_vm.$t("days")))])])],1),_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('p',[_vm._v("smoking cessation")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("12")]),_vm._v(" "+_vm._s(_vm.$t("days")))])])],1)],1):_vm._e(),(!_vm.showEdmontonSymptoms)?_c('QuestionnaireCardsContainer',{attrs:{"questionnaires":_vm.questionnaires['non-relapse-report'],"title":_vm.isLifeChamps
										? 'psychometric_reports'
										: 'patient_reported_outcome'}}):_vm._e(),(!_vm.showEdmontonSymptoms)?_c('QuestionnaireCardsContainer',{staticClass:"relapse-report",attrs:{"questionnaires":_vm.questionnaires['relapse-report'],"title":"relapse_report"}}):_vm._e(),(
									!_vm.showEdmontonSymptoms &
									(_vm.questionnaires['relapse-report'].length == 0) &
									(_vm.questionnaires['non-relapse-report'].length == 0)
								)?_c('p',{staticClass:"\n\t\t\t\t\t\t\t\t\ttext-center text-capitalize\n\t\t\t\t\t\t\t\t\tmt-10\n\t\t\t\t\t\t\t\t\ttitle\n\t\t\t\t\t\t\t\t\tno-data-available\n\t\t\t\t\t\t\t\t"},[_vm._v(" "+_vm._s(_vm.$t("no_data_available"))+"! ")]):_vm._e(),(_vm.showEdmontonSymptoms)?_c('EdmontonSymptoms',{attrs:{"reportedDate":_vm.reportedDate,"questionnaire":_vm.questionnaire}}):_vm._e()],1)],1),(_vm.hasProileTab)?_c('v-tab-item'):_vm._e(),(_vm.hasHistoryTab)?_c('v-tab-item',[_c('History',{attrs:{"history":_vm.history},on:{"comment":_vm.comment}})],1):_vm._e(),(_vm.hasMessagesTab)?_c('v-tab-item',[_c('Messages',{attrs:{"messages":_vm.messages}})],1):_vm._e()],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }