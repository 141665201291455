<template>
	<div class="members-profile" v-if="questionnaires">
		<v-container class="first-part">
			<Breadcrumbs v-if="member" />

			<v-row>
				<v-col class="d-flex pb-0 mb-12">
					<span class="icomoon icon-patient"></span>
					<h1 class="d-flex ml-2 page-title">
						{{ $t("profile") }}
					</h1>
				</v-col>
			</v-row>

			<BasicInfo :isLifeChamps="isLifeChamps" :member="member" />
		</v-container>

		<v-container
			fluid
			:class="`medical-info-container ${isLifeChamps ? 'mt-8' : 'mt-16'}`"
		>
			<v-container>
				<v-tabs v-model="tab" align-with-title class="mb-7 custom-tabs">
					<v-tabs-slider
						:color="
							$store.getters['auth/user']
								? $store.getters['auth/user'].theme_config.primary_color
								: ''
						"
					></v-tabs-slider>

					<v-tab v-for="item in items" :key="item">
						{{ item }}
					</v-tab>
				</v-tabs>

				<v-tabs-items v-model="tab" class="pl-5">
					<!-- the next line is commented for demo purpose and will uncommented after using dynamic component -->
					<!-- <v-skeleton-loader
            v-if="!questionnaire.questionnaire_id"
            type="card-heading, image"
          ></v-skeleton-loader> -->
					<v-tab-item>
						<v-container>
							<v-row class="programs mb-14" v-if="showEdmontonSymptoms">
								<v-col cols="12">
									<div class="h2 mb-5 pl-1 title">programs</div>
								</v-col>
								<v-col>
									<v-card flat>
										<p>adhera for cancer</p>
										<p class="mb-0">
											<strong>2</strong> months <strong>54</strong>
											{{ $t("days") }}
										</p>
									</v-card>
								</v-col>
								<v-col>
									<v-card flat>
										<p>diabetes</p>
										<p class="mb-0"><strong>54</strong> {{ $t("days") }}</p>
									</v-card>
								</v-col>
								<v-col>
									<v-card flat>
										<p>smoking cessation</p>
										<p class="mb-0"><strong>12</strong> {{ $t("days") }}</p>
									</v-card>
								</v-col>
							</v-row>
							<!-- the next lines is just for demo purpose and will be removed -->
							<QuestionnaireCardsContainer
								v-if="!showEdmontonSymptoms"
								:questionnaires="questionnaires['non-relapse-report']"
								:title="
									isLifeChamps
										? 'psychometric_reports'
										: 'patient_reported_outcome'
								"
							/>
							<QuestionnaireCardsContainer
								v-if="!showEdmontonSymptoms"
								:questionnaires="questionnaires['relapse-report']"
								title="relapse_report"
								class="relapse-report"
							/>

							<p
								v-if="
									!showEdmontonSymptoms &
									(questionnaires['relapse-report'].length == 0) &
									(questionnaires['non-relapse-report'].length == 0)
								"
								class="
									text-center text-capitalize
									mt-10
									title
									no-data-available
								"
							>
								{{ $t("no_data_available") }}!
							</p>

							<EdmontonSymptoms
								v-if="showEdmontonSymptoms"
								:reportedDate="reportedDate"
								:questionnaire="questionnaire"
							/>

							<!-- <component
                :is="questionnaireType[questionnaire.questionnaire_title]"
                :questionnaire="questionnaire"
                :reportedDate="reportedDate"
              /> -->
						</v-container>
					</v-tab-item>
					<v-tab-item v-if="hasProileTab"></v-tab-item>

					<v-tab-item v-if="hasHistoryTab">
						<History @comment="comment" :history="history" />
					</v-tab-item>

					<v-tab-item v-if="hasMessagesTab">
						<Messages :messages="messages" />
					</v-tab-item>
				</v-tabs-items>
			</v-container>
		</v-container>
	</div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

const components = {
	Breadcrumbs: () => import("@/components/Breadcrumbs.vue"),
	EdmontonSymptoms: () =>
		import("@/components/views/dashboard/members/id/EdmontonSymptoms.vue"),
	PsychometricReports: () =>
		import("@/components/views/dashboard/members/id/PsychometricReports.vue"),
	PatientReportedOutcome: () =>
		import(
			"@/components/views/dashboard/members/id/PatientReportedOutcome.vue"
		),
	QuestionnaireCardsContainer: () =>
		import(
			"@/components/views/dashboard/members/id/QuestionnaireCardsContainer.vue"
		),
	History: () =>
		import("@/components/views/dashboard/members/id/partial/tabs/History.vue"),
	Messages: () =>
		import("@/components/views/dashboard/members/id/partial/tabs/Messages.vue"),
	BasicInfo: () => import("@/components/shared/BasicInfo.vue"),
};

export default {
	data() {
		return {
			tab: null,
			questionnaire: {},
			reportedDate: null,
			questionnaireType: {
				"Symptoms report": "EdmontonSymptoms",
			},
			questionnaireComponentLoading: true,
			showEdmontonSymptoms: false,
			intervened: false,
			questionnaires: null,
			history: null,
			messages: [
				{
					date: 11,
					type: "type",
					message: "test",
					read: "now",
				},
			],
		};
	},

	components,

	computed: {
		isLifeChamps: function () {
			return (
				this.$store.getters["auth/user"]?.theme_config.key == "lifechamps"
			);
		},
		member: function () {
			return this.$store.getters["members/member"];
		},
		hasProileTab() {
			return this.$store.getters["auth/hasProileTab"];
		},
		hasMessagesTab() {
			return this.$store.getters["auth/hasMessagesTab"];
		},
		hasHistoryTab() {
			return this.$store.getters["auth/hasHistoryTab"];
		},
		items() {
			let tabs = [this.$t("health")];

			if (this.hasProileTab) {
				tabs.push(this.$t("profile"));
			}

			if (this.hasHistoryTab) {
				tabs.push(this.$t("history"));
			}

			if (this.hasMessagesTab) {
				tabs.push(this.$t("messages"));
			}

			return tabs;
		},
	},

	watch: {
		tab(newVal) {
			if (newVal == 2) {
				this.gethistory();
			}
		},
	},

	created() {
		this.getMember();
		this.getQuestionnaires();

		this.$store.dispatch("logActivity", {
			tag: "memeber_profile",
			type: 1,
			reference_id: this.$route.params.id,
		});
	},

	destroyed() {
		this.$store.dispatch("logActivity", {
			tag: "memeber_profile",
			type: 2,
			reference_id: this.$route.params.id,
		});
	},

	methods: {
		...mapActions({
			getMember: "members/getMember",
		}),
		getQuestionnaires() {
			axios
				.get(`/dashboard/questionnaire-summary/${this.$route.params.id}`)
				.then((res) => (this.questionnaires = res?.data?.data))
				.catch((err) => console.log(err));
		},
		async comment() {
			let data = { patient_id: 1002, result_option_id: 1, comment: "test" };
			await this.$axios
				.post("dashboard/users/contact-history", data)
				.then((res) => console.log(res))
				.catch((err) => console.log(err));
		},
		async gethistory() {
			await this.$axios
				.get(`dashboard/users/contact-history/${this.$route.params.id}`)
				.then((res) => {
					this.history = res.data.data;
				});
		},
	},
};
</script>

<style lang="scss" src="@/assets/sass/views/dashboard/members/id.scss"></style>
